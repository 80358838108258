body {
  background-color:  #000300;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --black-gradient: linear-gradient(
            144.39deg,
            #ffffff -278.56%,
            #6d6d6d -78.47%,
            #11101d 91.61%
    );
    --grey-gradient: linear-gradient(
            153.47deg,
            rgba(255, 255, 255, 0) -341.94%,
            #14101d 95.11%
    );
    --card-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

* {
    scroll-behavior: smooth;
}

.text-gradient {
    background: linear-gradient(
            157.81deg,
            #dc3545 -43.27%,
            #ec6323 -21.24%,
            #dc3545 120.94%,
            #a8eb12 90.29%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.bg-ssosec-gradient {
    background: linear-gradient(
            157.81deg,
            #dc3545 -43.27%,
            #ec6323 -21.24%,
            #dc3545 120.94%,
            #a8eb12 90.29%
    );
}

.bg-black-gradient {
    background: linear-gradient(
            144.39deg,
            #ffffff -278.56%,
            #6d6d6d -78.47%,
            #11101d 91.61%
    );
}

.bg-black-gradient-2 {
    background: linear-gradient(
            -168.39deg,
            #ffffff -278.56%,
            #6d6d6d -78.47%,
            #11101d 91.61%
    );
}

.bg-gray-gradient {
    background: linear-gradient(
            153.47deg,
            rgba(255, 255, 255, 0) -341.94%,
            #14101d 95.11%
    );
}

.box-shadow {
    box-shadow: 0px 20px 100px -10px rgba(66, 71, 91, 0.1);
}

.sidebar {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes slide-top {
    0% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slide-top {
    0% {
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

.feature-card:hover {
    background: var(--grey-gradient);
    box-shadow: var(--card-shadow);
}

.PhoneInputInput {
    /* This is done to stretch the contents of this component. */
    display: flex;
    align-items: center;
    background: rgb(55 65 81);
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-color: transparent;
    padding: 2px 2px 2px 4px;
    margin-left: 4px;
    border-radius: 0.5rem;
    height: 24px;
}

.loading:after {
    content: ' .';
    animation: dots 1s steps(5, end) infinite;}

@keyframes dots {
    0%, 20% {
        color: rgba(0,0,0,0);
        text-shadow:
                .25em 0 0 rgba(0,0,0,0),
                .5em 0 0 rgba(0,0,0,0);}
    40% {
        color: white;
        text-shadow:
                .25em 0 0 rgba(0,0,0,0),
                .5em 0 0 rgba(0,0,0,0);}
    60% {
        text-shadow:
                .25em 0 0 white,
                .5em 0 0 rgba(0,0,0,0);}
    80%, 100% {
        text-shadow:
                .25em 0 0 white,
                .5em 0 0 white;}}
